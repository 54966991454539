@import '../../../variable.less';

#legend {
  display: flex;
  flex-direction: row;

  .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5px 4px;
    margin-right: 8px;

    border: solid 0.5px @color-border-light-gray;
    border-radius: 4px;

    font-size: @font-size-10;
    line-height: 15px;
    color: @color-gray;

    .point {
      height: 10px;
      width: 10px;
      border-radius: 2px;

      margin-right: 4px;
    }
  }
}
