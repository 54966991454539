@import '../../variable.less';

#login-page {
  .form {
    .ant-form-item-extra {
      display: flex;
      justify-content: flex-end;

      .btn-forgot {
        margin-top: 8px;
      }
    }
  }
}
