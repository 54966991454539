@import '../../variable.less';

#gender-age {
  position: relative;
  display: flex;
  flex-direction: row;

  .unknow {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-row {
      .ant-col {
        display: flex;
        justify-content: center;
        padding: 4px 20px;
      }
    }
  }
}

#location-table {
  position: relative;
}
