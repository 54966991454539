@import '../../variable.less';

#card-data {
  height: calc(100% - 24px);
  padding-top: 16px;

  .wrapper-data {
    position: relative;
    .ant-avatar {
      margin-bottom: 12px;

      .icon-youtube {
        font-size: @font-size-14;
        margin-left: -4px;
      }
    }

    .ant-card-body {
      padding-bottom: 16px;
    }

    h4.ant-typography {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .row-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;

      .data {
        font-size: @font-size-21;
        line-height: 31px;
        font-weight: bold;

        margin-top: 4px;
      }

      .data-comparison {
        display: flex;
        align-items: center;

        margin-top: 12px;

        img {
          margin-top: -1px;
        }

        .text-comparison {
          color: @color-gray;

          .percentage {
            margin-left: 8px;
          }
        }
      }
    }

    .extra {
      font-size: @font-size-10;
      line-height: 14px;

      color: @color-gray;
      background-color: @color-background-lightwhitegray;

      padding: 4px;
      border-radius: 2px;
    }
  }
}
