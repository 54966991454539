@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?2284033');
  src: url('../font/fontello.eot?2284033#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?2284033') format('woff2'),
       url('../font/fontello.woff?2284033') format('woff'),
       url('../font/fontello.ttf?2284033') format('truetype'),
       url('../font/fontello.svg?2284033#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?2284033#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-basket:before { content: '\e800'; } /* '' */
.icon-wallet:before { content: '\e801'; } /* '' */
.icon-impression:before { content: '\e802'; } /* '' */
.icon-followers:before { content: '\e803'; } /* '' */
.icon-customers:before { content: '\e804'; } /* '' */
.icon-youtube:before { content: '\e805'; } /* '' */
.icon-search-1:before { content: '\e808'; } /* '' */
.icon-money:before { content: '\e809'; } /* '' */
.icon-back:before { content: '\e80a'; } /* '' */
.icon-logout:before { content: '\e80b'; } /* '' */
.icon-close:before { content: '\e80c'; } /* '' */
.icon-linked:before { content: '\e80d'; } /* '' */
.icon-basket-check:before { content: '\e80e'; } /* '' */
.icon-basket-fail:before { content: '\e80f'; } /* '' */
.icon-reach:before { content: '\e810'; } /* '' */
.icon-home:before { content: '\e811'; } /* '' */
.icon-performance:before { content: '\e812'; } /* '' */
.icon-marketplace:before { content: '\e813'; } /* '' */
.icon-campaign:before { content: '\e814'; } /* '' */
.icon-binding:before { content: '\e815'; } /* '' */
.icon-search:before { content: '\e816'; } /* '' */
