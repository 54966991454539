@import '../../variable.less';

#card-tab {
  height: calc(100% - 24px);

  .ant-card-body {
    .ant-tabs {
      .ant-tabs-nav {
        &::before {
          border: none;
        }

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            border: solid 1px @color-border-card;
            padding: 8px 12px;
            margin-right: 16px;

            font-size: @font-size-14;
            line-height: 21px;

            border-radius: 20px;

            &.ant-tabs-tab-active {
              border-color: @color-primary;
              background-color: @color-background-primary;
            }
          }

          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }
  }
}
