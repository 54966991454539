@import '../../variable.less';

#card-comparison {
  h4.ant-typography {
    margin-top: 8px;
  }

  .ant-card-body {
    height: calc(100% - 86px);

    .wrapper-data {
      min-height: 100%;
      position: relative;

      .row-value {
        display: flex;
        font-weight: bold;
        .data-value {
          font-size: @font-size-40;
        }
        .data-percentege {
          font-size: @font-size-18;
        }
      }

      .data-desc {
        margin-top: 8px;

        color: @color-gray;
        font-size: @font-size-12;
        line-height: 15px;
      }
    }

    .empty {
      position: absolute;
      top: 50%;
      @media (max-device-width: 480px) {
        top: 60%;
      }

      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      width: 250px;

      img {
        height: 64px;
        width: 64px;
      }

      .ant-typography {
        color: @color-gray;
        font-size: @font-size-14;
        text-align: center;
      }
    }
  }
}
