@import '../../../variable.less';

#dashboard-side-menu {
  margin: 40px;
  width: 272px;
  height: calc(100% - 80px);

  overflow: scroll;
  position: fixed;

  @media (max-device-width: 480px) {
    margin: 0px;
    box-shadow: none;
    width: 324px !important;
    max-width: 324px !important;

    max-height: 100vh;
    height: 100vh;
  }

  border-radius: 16px;
  background-color: @color-white;
  box-shadow: 0 5px 15px #14006314;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-title {
    padding: 30px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-device-width: 480px) {
      padding: 16px;
      padding-bottom: 56px;

      .close-sidebar {
        position: absolute;
        right: 12px;
      }
    }

    .bdd-logo {
      height: 24px;
    }

    span {
      font-size: @font-size-20;
      line-height: @font-size-24;

      font-weight: 600;

      color: @color-black;
    }
  }

  .ant-menu {
    border: none;
    color: @color-black;

    padding: 0px 16px;

    .ant-menu-sub {
      padding: 0px;
      background-color: @color-white;

      margin-left: 20px;
      margin-top: -8px;
      border-left: solid 1px @color-gray;

      .ant-menu-item {
        margin-left: 8px;
        width: calc(100% - 40px);
        height: 40px;

        .ant-menu-submenu-arrow {
          margin-top: -2px;
          transform: rotate(-90deg);
        }
      }
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background-color: @color-primary;
          color: @color-white;
          font-weight: bold;

          &:hover {
            color: @color-white;
            background-color: @color-primary;
          }

          .ant-menu-submenu-arrow {
            color: @color-white;
          }
        }

        .ant-menu-sub {
          border-left: solid 1px @color-primary;
        }
      }

      .ant-menu-submenu-title {
        background-color: @color-white;
        color: @color-gray;

        display: flex;
        align-items: center;

        height: 48px;
        margin: 8px 0px;
        border-radius: 6px;
        padding-left: 16px !important;
        &:hover {
          color: @color-black;
          background-color: @color-background-lightgray;
        }

        .ant-menu-submenu-arrow {
          color: @color-gray;
        }

        .icon-marketplace {
          width: 20px;
          margin-right: 14px;
        }
        .icon-campaign {
          width: 20px;
          margin-right: 14px;
          font-size: 14px;
        }
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;

      background-color: @color-white;
      color: @color-gray;

      height: 48px;
      margin: 8px 0px;
      border-radius: 6px;
      padding-left: 16px !important;

      &:hover {
        color: @color-black;
        background-color: @color-background-lightgray;
      }

      .ant-menu-submenu-arrow {
        color: @color-gray;
      }

      &.ant-menu-item-selected {
        background-color: @color-primary;
        color: @color-white;

        font-weight: bold;

        .ant-menu-submenu-arrow {
          color: @color-white;
        }
      }
    }
  }

  // .logout-btn {
  //   cursor: pointer;
  //   color: @color-gray;

  //   &:hover {
  //     color: @color-primary;
  //   }
  // }
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.no-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
