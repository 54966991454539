@import '../../../variable.less';

#dashboard-layout {
  min-height: 100vh;
  padding-left: 312px;
  background-color: @color-background-lightwhite;

  width: 100vw;

  @media (max-device-width: 480px) {
    padding-left: 0px;
  }

  .ant-layout-header {
    z-index: 1000;
    background-color: @color-background-lightwhite;
    padding: 40px;
    padding-left: 30px;
    margin-left: 10px;
    padding-bottom: 24px;

    width: calc(100vw - 322px);
    height: 152px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;

    .section-left-placeholder {
      display: flex;
      flex-direction: column;
      .greetings {
        height: 49px;
        width: 197px;
        border-radius: 6px;
      }
      .title {
        width: 240px;
        height: 32px;
        border-radius: 6px;

        margin-top: 8px;
      }
    }

    .section-right-placeholder {
      display: flex;
      flex-direction: column;

      align-items: flex-end;
      .account-section {
        display: flex;
        align-items: center;
        cursor: pointer;

        height: 40px;
        background-color: @color-white;

        padding-left: 12px;
        padding-right: 4px;

        margin-left: 24px;

        border-radius: 8px;

        .account {
          width: 91px;
          height: 21px;
          border-radius: 4px;
          margin-right: 20px;
        }

        .avatar {
          height: 32px;
          width: 32px;
          border-radius: 6px;
        }
      }

      .period {
        height: 32px;
        width: 150px;
        border-radius: 6px;
        margin-top: 16px;
      }
    }

    .header-left-section {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;

      .ant-typography {
        max-width: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .header-right-section {
      display: flex;
      flex-direction: column;

      .header-right-section-top {
        display: inline-flex;
        margin-bottom: 16px;
        justify-content: flex-end;

        .account-section {
          display: flex;
          align-items: center;
          cursor: pointer;

          height: 40px;
          background-color: @color-white;

          padding-left: 12px;
          padding-right: 4px;

          margin-left: 24px;

          border-radius: 8px;

          .account-name {
            width: max-content;
            margin-right: 20px;

            font-size: @font-size-14;
            color: @color-black;
            font-weight: 600;
          }

          .ant-avatar-square {
            background-color: @color-secondary;
          }
        }

        .header-share {
          height: 40px;
          width: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: @color-white;
          border-radius: 8px;

          margin-left: 8px;

          cursor: pointer;
        }
      }

      .header-right-section-bottom {
        display: inline-flex;
        justify-content: flex-end;

        .ant-select-selector {
          border-radius: 8px;
          border: none;

          width: 180px;

          font-size: @font-size-14;
        }
      }
    }
  }

  @media (max-device-width: 480px) {
    width: 100%;
    padding: 0px;
    margin: 0px;
    position: relative;

    .header-mobile {
      position: relative;
      width: 100%;
      height: fit-content;
      background-color: @color-primary;

      display: flex;
      flex-direction: column;

      .header-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;

        .header-menu {
          display: flex;
          flex: 0;
        }

        .header-logo {
          margin-left: 40px;

          @media (max-device-width: 320px) {
            margin-left: 0px;
          }
        }

        .header-profile {
          display: flex;
          flex-direction: row;
          .ant-avatar-square {
            background-color: @color-secondary;
          }
          .header-share {
            height: 32px;
            width: 32px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: @color-white;
            border-radius: 4px;

            margin-left: 8px;

            cursor: pointer;
          }
        }
      }

      .header-greetings {
        padding: 16px;
        padding-bottom: 42px;

        .ant-typography {
          max-width: inherit;
          text-overflow: ellipsis;
          overflow: hidden;

          color: @color-white;
        }

        h1 {
          font-size: @font-size-20;
          line-height: 26px;
        }

        h2 {
          font-size: @font-size-16;
          line-height: 21px;
          margin-top: 8px !important;
        }
      }

      .header-period {
        display: inline-flex;
        justify-content: flex-end;
        padding: 16px;
        width: 100%;

        position: absolute;
        bottom: -30px;

        .ant-select-selector {
          border-radius: 8px;
          border: none;

          width: 180px;

          font-size: @font-size-14;
        }
      }
    }
  }

  .ant-layout-content {
    margin-top: 152px;
    padding: 0px 40px;

    @media (max-device-width: 480px) {
      padding: 0px 16px;
    }

    .status {
      padding: 16px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      min-height: 70px;
      box-shadow: 0 5px 15px #14006314;

      .info {
        display: flex;

        img {
          margin-right: 14px;
        }
      }

      &.syncing {
        background-color: #ffe8ce;
      }

      &.synced {
        background-color: #c5fbd4;

        @media (max-device-width: 480px) {
          align-items: flex-start;
        }

        .icon-close {
          color: @color-gray;
          font-size: @font-size-24;

          @media (max-device-width: 480px) {
            font-size: @font-size-16;
          }

          cursor: pointer;
        }
      }
    }
  }
}

#share-token {
  max-width: 350px;
  margin-top: 16px;
  padding: 8px;

  border-radius: 4px;

  font-size: @font-size-12;

  background-color: @color-background-lightwhite;
}

#link-copied {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  img {
    height: 16px;
    width: 16px;

    margin-right: 6px;
    margin-top: -2px;
  }
}
