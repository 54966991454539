@import '../../variable.less';

#card-line-chart {
  height: calc(100% - 24px);

  display: flex;
  flex-direction: column;

  .ant-card-body {
    height: 100%;
  }

  .data-wrapper {
    min-height: 100%;
    position: relative;

    .extra-row {
      @media (max-device-width: 480px) {
        position: relative;
        // margin-top: -16px;
        z-index: 0;
      }

      position: absolute;
      top: -16px;
      z-index: 10;

      .extra {
        font-size: @font-size-10;
        line-height: 14px;

        color: @color-gray;
        background-color: @color-background-lightwhitegray;

        padding: 4px;
        border-radius: 2px;
      }
    }

    .empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      width: 250px;

      img {
        height: 64px;
        width: 64px;
      }

      .ant-typography {
        margin-top: 16px;
        color: @color-gray;
        font-size: @font-size-14;
        text-align: center;
      }
    }
  }
}

#card-line-chart-loading {
  height: calc(100% - 24px);
  .ant-card-body {
    margin-top: 14px;

    .chart-placeholder {
      width: 100%;
      height: 172px;

      border-radius: 12px;

      margin-top: 8px;
    }
  }
}
