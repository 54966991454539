@import '../../variable.less';

#card-binding {
  padding-top: 16px;
  border-radius: 12px;

  border-color: @color-border-gray;

  .data-wrapper {
    position: relative;
    .account-list-loading {
      margin: 16px -16px -16px;
      padding: 0px 16px 16px;
      border-top: solid 1px @color-border-gray;

      .account-item {
        width: 100%;
        height: 20px;

        border-radius: 4px;

        margin-top: 16px;
      }
    }

    .account-list {
      margin: 16px -16px -16px;
      padding: 0px 16px 16px;
      border-top: solid 1px @color-border-gray;

      .account-item {
        display: flex;
        flex-direction: row;
        margin-top: 16px;

        .account-item-left {
          display: flex;
          flex-direction: row;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;

          align-items: center;

          .ant-avatar {
            background-color: @color-secondary;
            color: @color-white;
            font-size: @font-size-10;
            font-weight: 600;

            margin-right: 12px;
          }

          .account-name {
            font-size: @font-size-14;
            line-height: 24px;

            max-width: calc(100% - 36px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .icon-linked {
        color: @color-primary;
        font-size: @font-size-20;
      }

      .row-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ant-btn {
          width: 49%;
        }
      }
    }
    .icon-account {
      width: 32px;
      height: 32px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 6px;

      margin-bottom: 8px;

      &.tokopedia {
        background-color: @icon-bg-tokopedia;
      }
      &.shopee {
        background-color: @icon-bg-shopee;
      }
      &.shopify {
        background-color: @icon-bg-shopify;
      }
      &.facebook {
        background-color: @icon-bg-facebook-ads;
      }
      &.google {
        &.ads {
          background-color: @icon-bg-google-ads;
        }

        &.analytics {
          background-color: @icon-bg-google-analytic;
        }

        img {
          width: 20px;
        }
      }
      &.instagram {
        background-color: @icon-bg-instagram;
      }
    }

    .ant-btn {
      margin-top: 20px;
      margin-bottom: 8px;
      height: 40px;

      color: @color-white;
      font-weight: bold;

      border-radius: 6px;
    }
  }
}
