@import '../../variable.less';

.register-steper {
  background-color: #f0f0f0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  padding: 120px 40px;

  .item-steper {
    display: flex;
    flex-direction: row;
    margin-bottom: 64px;

    .item-point-steper {
      height: 24px;
      width: 24px;

      border: solid 1px @color-border-gray;
      border-radius: 12px;

      background-color: @color-white;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      font-size: 12px;

      &.done {
        background-color: @color-success;
        border-color: @color-success;
        color: @color-white;
      }

      &.active {
        background-color: @color-primary;
        border-color: @color-primary;
        color: @color-white;
      }
    }
  }
}

.item-steper-mobile {
  display: flex;
  margin: 0px -8px 24px;

  .item-steper {
    display: flex;
    flex: 1;

    flex-direction: column;

    margin: 0px 8px;

    .steper {
      padding: 24px 4px 14px;

      display: flex;
      flex: 1;

      flex-direction: row;
      align-items: center;

      .item-point-steper {
        height: 18px;
        width: 18px;

        border: solid 1px @color-border-gray;
        border-radius: 9px;

        background-color: @color-white;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 8px;

        font-size: 12px;

        &.done {
          background-color: @color-success;
          border-color: @color-success;
          color: @color-white;
        }

        &.active {
          background-color: @color-primary;
          border-color: @color-primary;
          color: @color-white;
        }
      }
    }

    .marker {
      height: 4px;
      width: 100%;

      background-color: @color-border-gray;

      border-radius: 2px;

      &.active {
        background-color: @color-primary;
      }
    }
  }
}

.mobile-select-industry {
  margin-top: -18px;
}

.select-industry {
  margin-top: 30px !important;

  .card-industry {
    display: flex;
    flex-direction: column;

    height: 110px;
    padding: 16px;

    border: solid 1px @color-border-card;
    border-radius: 12px;

    font-size: @font-size-12;
    font-weight: 600;

    cursor: pointer;

    img {
      height: 32px;
      width: fit-content;

      margin-bottom: 12px;
    }

    &.selected {
      background-color: @color-background-lightblue;
      border-color: @color-primary;
    }
  }
}

#register-industry-page {
  padding: 48px 40px 69px !important;

  @media (max-device-width: 480px) {
    padding: 30px 25px !important;

    .form {
      &.finalize {
        margin-top: 0px;
        .ant-row {
          .ant-col {
            width: 100%;
          }
        }

        .ant-form-item {
          width: 100%;
        }

        .row-submit {
          display: flex;
          justify-content: flex-end;

          padding: 0px 12px;

          .ant-btn {
            padding: 10px 42px;
          }
        }
      }
    }
  }

  @media (min-device-width: 480px) {
    .form {
      &.finalize {
        margin-top: 30px;

        .row-submit {
          display: flex;
          justify-content: flex-end;

          padding: 0px 12px;

          .ant-btn {
            padding: 10px 42px;
          }
        }
      }
    }
  }
}
