.loadingWrapper {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-device-width: 480px) {
    z-index: 0;
  }

  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading {
    display: contents;
  }
}
