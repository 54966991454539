@import '../../variable.less';

#card-donut-chart {
  height: calc(100% - 24px);

  h4.ant-typography {
    margin-top: 8px;
  }

  .ant-card-body {
    padding-bottom: 24px;
  }

  .col-legend {
    display: flex;
    flex-direction: column;

    .legend {
      margin-top: 16px;

      .legend-item {
        border: solid 0.5px @color-border-light-gray;
        border-radius: 6px;
        margin-bottom: 12px;

        padding: 8px;

        font-size: @font-size-14;
        line-height: 18px;

        .point-name {
          display: flex;
          align-items: center;

          .point {
            height: 12px;
            width: 12px;
            border-radius: 2px;

            margin-right: 8px;
          }

          .label {
            color: @color-gray;
          }
        }

        .value {
          margin-left: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .col-chart {
    display: flex;
    justify-content: flex-end;
  }
}

#card-donut-chart-loading {
  height: calc(100% - 24px);

  h4.ant-typography {
    margin-top: 8px;
  }

  .ant-card-body {
    padding-bottom: 24px;
  }
  .col-left {
    display: flex;
    flex-direction: column;

    .total {
      height: 32px;
      width: 56px;
      border-radius: 4px;

      margin-bottom: 16px;
    }

    .description {
      height: 40px;
      width: 140px;
      border-radius: 4px;
      margin-bottom: 12px;
    }

    .legend {
      display: inline-flex;
      .legend-item {
        display: flex;
        align-items: center;

        margin-right: 8px;

        .pointer {
          height: 8px;
          width: 8px;
          border-radius: 4px;

          margin-right: 4px;
        }

        .legend-name {
          font-size: @font-size-10;
          line-height: 15px;
          color: @color-gray;
        }
      }
    }
  }

  .col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 4px;

    .donut-chart {
      height: 140px;
      width: 140px;
      border-radius: 70px;
    }
  }
}
