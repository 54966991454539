@import '../../variable.less';

#card-bar-chart {
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-card-body {
    height: 100%;
  }

  .data-wrapper {
    position: relative;
    height: 100%;

    .empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      width: 250px;

      img {
        height: 64px;
        width: 64px;
      }

      .ant-typography {
        margin-top: 16px;
        color: @color-gray;
        font-size: @font-size-14;
        text-align: center;
      }
    }
  }
}

#card-bar-chart-loading {
  .ant-card-body {
    margin-top: 14px;

    .chart-placeholder {
      width: 100%;
      height: 240px;

      border-radius: 12px;

      margin-top: 8px;
    }
  }
}
