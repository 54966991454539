@import '../../variable.less';

#profile {
  .ant-card-body {
    padding: 0px 40px 91px;

    @media (max-device-width: 480px) {
      padding: 0px 16px;
      &::before {
        width: calc(100% + 32px) !important;
        margin: 0px -16px !important;
      }
    }

    &::before {
      height: 125px;
      width: calc(100% + 80px);
      margin: 0px -40px;

      background-color: @color-primary;

      border-radius: 16px;
    }

    .row-profile {
      display: flex;
      flex-direction: row;

      @media (max-device-width: 480px) {
        flex-direction: column;
        align-items: center;

        text-align: center;
      }

      .avatar {
        border: solid 2px @color-white;
        border-radius: 50px;

        width: 100px;
        height: 100px;

        background-color: @color-primary;

        display: flex;
        justify-content: center;
        align-items: center;

        color: @color-white;
        font-size: 34px;

        margin-top: -25px;
      }

      .info {
        margin: 8px 16px;

        h4 {
          font-size: 21px;
          line-height: 32px;
        }
      }
    }

    .form {
      margin-top: 40px;

      @media (max-device-width: 480px) {
        margin-top: 16px;
      }

      .ant-form-item-label {
        label {
          font-size: @font-size-12;
          line-height: 18px;

          color: @color-gray;
        }
      }

      .ant-select-disabled {
        .ant-select-selector {
          color: @color-black;
        }
      }

      .ant-form-item-control-input-content {
        .input-text,
        .ant-select-selector,
        .ant-input-password {
          align-items: center;

          height: 40px;
          border-radius: 8px;

          font-size: @font-size-16;
          font-weight: 600;

          .ant-input {
            font-size: @font-size-16;
            font-weight: 600;
          }

          &.ant-input-disabled {
            color: @color-black;
          }

          .ant-select-selection-item {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              margin-right: 8px;
            }
          }
        }
      }

      .ant-form-item.button {
        .ant-form-item-control-input-content {
          margin-top: 32px;

          display: flex;
          justify-content: flex-end;

          @media (max-device-width: 480px) {
            margin-top: 16px;
            .ant-btn {
              width: 100%;
              border-radius: 8px;
            }
          }

          .ant-btn {
            height: 40px;
            padding: 8px 40px;
            font-weight: 600;
          }
        }
      }

      .ant-form-item-explain {
        font-size: @font-size-12;
        line-height: 26px;
        height: 26px;
      }

      .ant-form-item-required::before {
        display: none;
      }
    }
  }
}

.industry-item-option {
  display: flex;
  align-items: center;

  img {
    height: 20px;
    margin-right: 8px;
  }
}
