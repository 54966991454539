#menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;

  .menu-icon {
    width: 20px;
    margin-right: 14px;

    .icon-binding {
      width: 20px;
      margin-right: 14px;
      font-size: 12px;
    }
  }

  p {
    margin: 0;
  }
}
