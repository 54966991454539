@import '../../variable.less';

#card-list {
  height: calc(100% - 24px);

  .ant-card-body {
    height: calc(100% - 86px);

    .wrapper-data {
      min-height: 100%;
      position: relative;
    }

    .empty {
      position: absolute;
      top: 50%;
      @media (max-device-width: 480px) {
        top: 60%;
      }

      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      width: 250px;

      img {
        height: 64px;
        width: 64px;
      }

      .ant-typography {
        color: @color-gray;
        font-size: @font-size-14;
        text-align: center;
      }
    }
  }

  .ant-card-actions {
    border: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    margin-top: -24px;

    .ant-btn-link {
      font-size: @font-size-14;
      font-weight: 600;
    }
  }
}

#list-data {
  margin: 0;
  padding-left: 20px;
  font-size: @font-size-14;

  li {
    margin-bottom: 14px;

    .list-row {
      width: 100%;

      display: inline-flex;
      justify-content: space-between;

      white-space: nowrap;

      .ant-typography {
        &.label {
          max-width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.value {
          font-weight: 600;
        }
      }
    }
  }
}

#modal-list {
  margin-bottom: -16px;

  #list-data {
    margin: 16px 0px;
  }

  .btn-row {
    display: flex;
    justify-content: center;

    .ant-btn-link {
      font-weight: 600;
    }
  }
}
