@import '../../../variable.less';

#line-chart {
  .total {
    font-size: @font-size-21;
    line-height: 27px;

    font-weight: 600;
  }
}
