@import '../../variable.less';

#modal-binding-shopify {
  display: flex;
  flex-direction: column;

  justify-content: center;

  text-align: left;

  h4 {
    font-weight: 600;
    margin: 18px 0px;
  }

  .form {
    .ant-form-item-label {
      label {
        font-size: @font-size-12;
        line-height: 18px;

        color: @color-gray;
      }
    }

    .ant-select-disabled {
      .ant-select-selector {
        color: @color-black;
      }
    }

    .ant-form-item-control-input-content {
      .input-text {
        align-items: center;

        height: 40px;
        border-radius: 8px;

        font-size: @font-size-16;
        font-weight: 600;

        .ant-input {
          font-size: @font-size-16;
          font-weight: 600;
        }

        &.ant-input-disabled {
          color: @color-black;
        }
      }
    }
    .ant-form-item-explain {
      font-size: @font-size-12;
      line-height: 26px;
      height: 26px;
    }

    .ant-form-item-required::before {
      display: none;
    }
  }

  .row-btn {
    display: flex;
    justify-content: center;
    width: 100%;

    .ant-btn {
      height: 48px;
      border-radius: 8px;

      @media (max-device-width: 480px) {
        width: 100%;
        margin: 8px 0px;
      }

      padding: 10px 56px;
      margin-top: 36px;
      margin-bottom: 8px;

      font-weight: bold;

      &:disabled {
        background-color: @color-gray;
        border-color: @color-gray;

        color: @color-white;
      }
    }
  }
}
