@import '../../variable.less';

#card-horizontal-bar-chart-loading {
  .list-row {
    width: 100%;
    height: 16px;

    border-radius: 4px;

    margin-top: 8px;
  }
}
