@import '../../variable.less';

#card-donut-list-chart {
  height: calc(100% - 24px);

  .ant-card-actions {
    border: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    margin-top: -16px;

    .ant-btn-link {
      font-size: @font-size-14;
      font-weight: 600;
    }
  }
}

#legend-chart {
  .legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 8px;

    .legend-item-left {
      display: flex;
      align-items: center;

      .pointer {
        height: 10px;
        width: 10px;
        border-radius: 5px;

        margin-right: 8px;
      }

      .legend-name {
        font-size: @font-size-12;
        line-height: 18px;
        color: @color-black;
      }
    }

    .legend-value {
      font-size: @font-size-12;
      line-height: 18px;
      color: @color-black;

      font-weight: 600;
    }
  }
}

#card-donut-list-chart-loading {
  .ant-card-body {
    margin-top: 14px;

    .chart-placeholder {
      width: 100%;
      height: 240px;

      border-radius: 12px;

      margin-top: 8px;
    }
  }
}

#modal-chart {
  margin-bottom: -16px;

  h4 {
    margin-bottom: 16px;
  }

  #legend-chart {
    margin: 16px 0px;
  }

  .btn-row {
    display: flex;
    justify-content: center;

    .ant-btn-link {
      font-weight: 600;
    }
  }
}
