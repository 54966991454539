@import '../../../variable.less';

#landing-page {
  background-color: @color-background-white;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @media (max-device-width: 480px) {
    .logo-img {
      background-color: @color-primary;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        height: 24px;
        margin: 38px 0px;
      }
    }

    .content {
      width: 100vw;
      background-color: @color-white;

      .card {
        border-radius: 0px;
        box-shadow: none;
        border: none;

        .ant-card-body {
          padding: 0px;
          .ant-row {
            .landing-image {
              display: none;
            }

            .landing-content {
              padding: 30px 25px;

              .icon-back {
                margin-right: 14px;
                cursor: pointer;
              }

              .row-social-btn {
                display: flex;
                flex-direction: row;

                margin: 24px -8px;

                justify-content: space-between;

                .ant-btn {
                  font-size: @font-size-16;

                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;

                  color: @color-black;

                  img {
                    margin-right: 15px;
                  }

                  margin: 0px 8px;
                }
              }

              .ant-divider-inner-text {
                color: @color-gray;
                font-size: @font-size-12;
                line-height: 18px;
              }

              .form {
                .ant-form-item-label {
                  label {
                    font-size: @font-size-12;
                    line-height: 18px;
                  }
                }

                .ant-form-item-control-input-content {
                  .input-text,
                  .ant-select-selector,
                  .ant-input-password {
                    align-items: center;

                    height: 40px;
                    border-radius: 8px;

                    font-size: @font-size-16;
                    font-weight: 600;

                    .ant-input {
                      font-size: @font-size-16;
                      font-weight: 600;
                    }
                  }
                }
                .ant-form-item-explain {
                  font-size: @font-size-12;
                  line-height: 26px;
                  height: 26px;
                }

                .ant-form-item-required::before {
                  display: none;
                }
              }

              .ant-btn {
                height: 48px;
                border-radius: 8px;
                color: @color-primary;

                font-weight: bold;

                &.submit-btn {
                  margin: 14px 0px;
                  font-size: @font-size-18;
                  background-color: @color-primary;
                  color: @color-white;

                  &:hover,
                  &:focus {
                    border-color: @color-primary;
                  }
                }

                &.ant-btn-link {
                  font-size: @font-size-14;
                  line-height: 21px;
                  color: @color-primary;

                  font-weight: 600;

                  padding: 0;
                  height: 21px;
                }
              }

              .row-footer {
                text-align: center;
                .ant-typography {
                  font-size: @font-size-14;
                }
              }
            }
          }
        }
      }
    }

    .footer {
      display: none;
    }
  }

  @media (min-device-width: 480px) {
    .logo-img {
      height: 40px;
      margin: 90px 0px 45px;
    }

    .content {
      display: flex;
      flex: initial;
      justify-content: center;

      margin-bottom: 40px;

      .card {
        width: 1000px;
        height: 600px;

        border: 4px solid @color-white;
        border-radius: 18px;
        box-shadow: 0 5px 15px #14006314;
        padding: 0px;

        display: flex;
        justify-content: center;

        .ant-card-body {
          width: 100%;
          padding: 0px;

          .ant-row {
            height: 100%;

            .landing-image {
              height: 100%;
              width: 100%;
              display: flex;

              padding: 1px;

              .ant-typography {
                position: absolute;
                padding: 48px 60px;

                color: @color-white;
              }

              img {
                border-top-left-radius: 14px;
                border-bottom-left-radius: 14px;

                height: 100%;
                width: 100%;
              }
            }

            .landing-content {
              padding: 48px 88px 69px 88px;

              .icon-back {
                margin-right: 14px;
                cursor: pointer;
              }

              .row-social-btn {
                display: flex;
                flex-direction: row;

                margin: 24px 0px;

                justify-content: space-between;

                .ant-btn {
                  width: 154px;
                  font-size: @font-size-16;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  color: @color-black;

                  img {
                    margin-right: 15px;
                  }
                }
              }

              .ant-divider-inner-text {
                color: @color-gray;
                font-size: @font-size-12;
                line-height: 18px;
              }

              .form {
                .ant-form-item-label {
                  label {
                    font-size: @font-size-12;
                    line-height: 18px;
                  }
                }

                .ant-form-item-control-input-content {
                  .input-text,
                  .ant-select-selector,
                  .ant-input-password {
                    align-items: center;

                    height: 40px;
                    border-radius: 8px;

                    font-size: @font-size-16;
                    font-weight: 600;

                    .ant-input {
                      font-size: @font-size-16;
                      font-weight: 600;
                    }
                  }
                }
                .ant-form-item-explain {
                  font-size: @font-size-12;
                  line-height: 26px;
                  height: 26px;
                }

                .ant-form-item-required::before {
                  display: none;
                }
              }

              .ant-btn {
                height: 48px;
                border-radius: 8px;
                color: @color-primary;

                font-weight: bold;

                &.submit-btn {
                  margin: 14px 0px;
                  font-size: @font-size-18;
                  background-color: @color-primary;
                  color: @color-white;

                  &:hover,
                  &:focus {
                    border-color: @color-primary;
                  }
                }

                &.ant-btn-link {
                  font-size: @font-size-14;
                  line-height: 21px;
                  color: @color-primary;

                  font-weight: 600;

                  padding: 0;
                  height: 21px;
                }
              }

              .row-footer {
                text-align: center;
                .ant-typography {
                  font-size: @font-size-14;
                }
              }
            }
          }
        }
      }
    }

    .footer {
      color: @color-gray;
      font-size: @font-size-14;
    }
  }
}
