@import '../../variable.less';

#card-table {
  height: calc(100% - 24px);

  @media (max-device-width: 480px) {
    height: 100%;
  }

  .ant-card-body {
    height: calc(100% - 94px);
  }

  .ant-card-actions {
    border: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    margin-top: -16px;

    @media (max-device-width: 480px) {
      border-radius: 0px;
    }

    .ant-btn-link {
      font-size: @font-size-14;
      font-weight: 600;
    }
  }

  .data-wrapper {
    position: relative;

    @media (max-device-width: 480px) {
      margin: 0px -16px;

      padding: 0px 10px;
    }
  }

  @media (max-device-width: 480px) {
    margin: 0px -16px;
    border-radius: 0px;
  }
}

#list-table {
  .ant-table-cell {
    padding: 4px 8px;
    border: none;
  }

  .ant-table-thead > tr > th {
    background-color: @color-white;
    font-size: @font-size-14;
    line-height: 21px;

    color: @color-gray;

    padding: 0px 8px 8px;
  }

  .ant-table-tbody > tr > td {
    font-size: @font-size-12;
    line-height: 16px;

    padding: 12px 8px;
  }

  .ant-table-tbody {
    .ant-table-row {
      :first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.even {
        background-color: @color-background-whiteblue;

        td {
          padding: 3px 8px;
        }
      }
    }
  }
}

#modal-table {
  margin-bottom: -16px;

  @media (max-device-width: 480px) {
    margin: 0px -8px -16px;
  }

  #list-table {
    margin: 16px -6px;
  }

  .btn-row {
    display: flex;
    justify-content: center;

    .ant-btn-link {
      font-weight: 600;
    }
  }
}
