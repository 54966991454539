@import '../../variable.less';

#modal-binding {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  text-align: left;

  h4 {
    font-weight: 600;
    margin: 18px 0px;
  }

  .info-desc {
    display: flex;
    width: 100%;
    color: @color-grey-light;

    font-size: @font-size-12;
    line-height: 16px;

    margin-bottom: 12px;
  }

  .info-row {
    width: 100%;

    background-color: @color-background-lightwhitegray;

    padding: 8px;
    border-radius: 8px;

    margin-top: 16px;
  }

  .account-list {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-height: 164px;

    &.account-confirm {
      max-height: none;
    }

    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    .row-confirm {
      margin: 8px 0px;
      padding: 8px;

      border: solid 1px @color-border;
      border-radius: 8px;

      .row-account-confirm {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        justify-content: space-between;

        .row-account-left {
          position: relative;

          .ant-avatar {
            background-color: @color-secondary;
            color: @color-white;
            font-size: 10;
            font-weight: 600;

            margin-right: 12px;

            width: 24px;
            height: 24px;

            position: absolute;
          }

          .ant-typography {
            margin-left: 36px;
            width: 390px;

            @media (max-device-width: 480px) {
              width: calc(100vw - 110px);
            }

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .row-account-desc {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        color: @color-grey-light;

        font-size: @font-size-12;
        line-height: 16px;
      }
    }

    .row-account {
      display: flex;
      align-items: center;
      width: 100%;

      justify-content: space-between;

      margin: 8px 0px;

      .row-account-left {
        position: relative;

        .ant-avatar {
          background-color: @color-secondary;
          color: @color-white;
          font-size: 10px;
          font-weight: 600;

          position: absolute;
        }

        .ant-typography {
          margin-left: 36px;
          width: 410px;

          @media (max-device-width: 480px) {
            width: calc(100vw - 90px);
          }

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .ant-radio-group {
      .ant-radio-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        align-items: center;

        padding: 8px 0px;

        span {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0;

          &.ant-radio {
            flex: 0;
          }
        }

        .row-account-left {
          display: inline-flex;

          .ant-avatar {
            background-color: @color-primary;
            color: @color-white;
            font-size: 10;
            font-weight: 600;

            margin-right: 12px;
          }
        }
      }
    }
  }

  .row-button {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-device-width: 480px) {
      margin-top: 16px;
      .ant-btn-default {
        width: 100%;
        margin-right: 8px;
      }

      .ant-btn-primary {
        width: 100%;
        margin-left: 8px;
      }
    }
  }

  .ant-btn {
    height: 48px;
    border-radius: 8px;

    @media (max-device-width: 480px) {
      width: 100%;
      margin: 8px 0px;
    }

    padding: 10px 56px;
    margin-top: 36px;
    margin-bottom: 8px;

    font-weight: bold;

    &:disabled {
      background-color: @color-gray;
      border-color: @color-gray;

      color: @color-white;
    }
  }
}
