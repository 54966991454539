@import './variable.less';

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Italic.ttf');
  font-style: italic;
}

@primary-color: @color-primary; // primary color for all components
@link-color: @color-primary; // link color
@success-color: @color-primary; // success state color
@warning-color: @color-gray; // warning state color
@error-color: @color-danger; // error state color
@font-size-base: 16px; // major text font size
@heading-color: @color-black; // heading text color
@text-color: @color-black; // major text color
@text-color-secondary: @color-gray; // secondary text color
@disabled-color: @color-gray; // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: @color-border; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

@font-family: ibmplex;

h1.ant-typography {
  font-size: @font-size-32;
  line-height: 48px;
  margin: 0;
  font-weight: bold;
}

h2.ant-typography {
  font-size: @font-size-21;
  line-height: 31.5px;
  font-weight: bold;
  margin: 0 !important;
}

h3.ant-typography {
  font-size: @font-size-18;
  line-height: 27px;
  font-weight: bold;
  margin: 0px !important;
}

h4.ant-typography {
  font-size: @font-size-16;
  font-weight: 400;
  margin: 0;
}

h5.ant-typography {
  font-size: @font-size-14;
  font-weight: 400;
  margin: 0;
}

.text-success {
  color: @color-success !important;
}

.text-warning {
  color: @color-warning !important;
}

.text-danger {
  color: @color-danger !important;
}

.ant-card-body {
  padding: 15px;
  width: 100%;
}

.ant-tooltip {
  font-size: @font-size-10;
  z-index: 10000;

  .ant-tooltip-inner {
    padding: 4px;
    min-height: 0;
  }
}

.ant-select-item {
  font-size: @font-size-14;
}

.ant-card {
  box-shadow: 0 5px 15px #14006314;
  border-radius: 16px;
  margin-bottom: 24px;

  &.no-bottom {
    .ant-card-body {
      padding-bottom: 0px;

      #card-data,
      #card-data-loading {
        border: solid 0.5px #ccc;
      }
    }

    @media (max-device-width: 480px) {
      margin: 24px -16px;
      border-radius: 0px;
    }
  }

  .ant-card-head {
    border: none;
    padding: 16px;

    .ant-card-head-title {
      padding: 0px;
      font-size: @font-size-16;
    }
  }

  .ant-card-body {
    padding: 16px;
    padding-top: 0px;
    min-height: 124px;
  }
}

.ant-checkbox-inner {
  border-color: @color-primary !important;
}

.ant-tabs-card {
  @media (max-device-width: 480px) {
    margin: 0px -16px;

    .ant-tabs-nav {
      margin-bottom: 0px;

      &::before {
        border: none;
      }

      .ant-tabs-tab {
        border: none;
        background-color: transparent;
        padding: 8px 12px;

        margin: 0px 0px 8px 16px;

        .ant-tabs-tab-btn {
          color: @color-black;
          font-size: @font-size-16;
          line-height: 24px;
        }

        &.ant-tabs-tab-active {
          background-color: @color-white;
          border-radius: 8px;
        }
      }
    }

    .ant-tabs-content-holder {
      background-color: @color-white;
      padding: 16px;

      border-radius: 0px;

      min-height: calc(100vh - 234px);
    }
  }

  @media (min-device-width: 480px) {
    .ant-tabs-nav {
      margin-bottom: -16px;

      &::before {
        border: none;
      }

      .ant-tabs-tab {
        border: none;
        background-color: transparent;
        padding: 10px 24px 26px;

        .ant-tabs-tab-btn {
          color: @color-black;
          font-size: @font-size-16;
          line-height: 24px;
        }

        &.ant-tabs-tab-active {
          background-color: @color-white;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
    }
    .ant-tabs-content-holder {
      background-color: @color-white;
      padding: 40px 24px 24px;

      border-radius: 16px;

      min-height: calc(100vh - 234px);
    }
  }
}

.ant-modal-content {
  border-radius: 16px;
}

.unbind {
  flex-direction: column;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 240px;

    .unbind-text {
      text-align: center;
    }

    .ant-btn {
      margin-top: 32px;
    }
  }

  .unbind-text {
    font-size: @font-size-14;
    line-height: 21px;

    color: @color-grey-disabled;

    margin: 2px 0px 8px;
  }

  .ant-btn {
    height: 32px;
    color: @color-primary;
    border-color: @color-primary;

    font-size: @font-size-14;

    margin-bottom: 10px;
    max-width: 184px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      width: 100%;
    }
  }
}

.loading {
  background: #e5e5e5;
  background-image: linear-gradient(
    to right,
    #e5e5e5 0%,
    #f6f7f8 20%,
    #e5e5e5 40%,
    #e5e5e5 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.modal-binding,
.modal-tokopedia,
.modal-shopify {
  @media (max-device-width: 480px) {
    margin: 0px;
    max-width: 100% !important;

    .ant-modal-content {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .ant-modal-body {
      padding: 16px;
    }
  }
}

.sidebar-mobile {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  max-height: 100vh;

  padding: 0px;

  .slide-pane__content {
    padding: 0px;
    max-height: 100vh;
  }
}

.ant-carousel {
  margin: 0px -16px;

  .carousel-summary {
    .slick-list {
      #card-list {
        margin-right: 16px !important;
        min-height: 180px !important;
      }
    }
  }

  .slick-list {
    padding: 0px 16px !important;

    #card-data {
      margin-right: 16px !important;
    }

    #card-comparison {
      margin-right: 16px !important;
    }

    #card-list {
      margin-right: 16px !important;
      min-height: 320px !important;
    }

    #card-donut-list-chart {
      margin-right: 16px !important;
      min-height: 320px !important;
    }
  }

  .slick-dots {
    position: relative;
    z-index: 0;
    justify-content: flex-start;
    margin-left: 16px;

    li {
      background-color: rgba(239, 119, 82, 0.3);
      width: 8px;
      height: 8px;
      border-radius: 4px;

      margin: 0px 2px;

      button {
        background-color: rgba(239, 119, 82, 0.3);
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }

      &.slick-active {
        width: 16px;
        height: 8px;
        border-radius: 4px;
        background-color: rgb(239, 119, 82);

        button {
          width: 16px;
          height: 8px;
          border-radius: 4px;
          background-color: rgb(239, 119, 82);
        }
      }
    }
  }
}

.ant-notification-notice-message {
  font-size: @font-size-16;
  font-weight: 600;
}
.ant-notification-notice-description {
  font-size: @font-size-14;
}

.introjs-tooltipReferenceLayer * {
  font-family: ibmplex !important;
}

.tutorial {
  position: relative;
  border-radius: 16px;
  margin: 10px;
  padding: 20px;

  width: 4600px;
  max-width: 460px;

  @media (max-device-width: 480px) {
    width: 320px;
    max-width: 320px;
  }

  &.introjs-bottom-right-aligned {
    right: 300px !important;
  }

  .introjs-tooltip-header {
    padding: 0px;
    .introjs-skipbutton {
      display: none;
    }
  }

  .introjs-tooltiptext {
    padding: 0px;
    padding-top: 12px;
    font-size: @font-size-14;
  }

  .introjs-bullets {
    position: absolute;
    margin-top: 20px;

    width: 460px;
    max-width: 460px;

    @media (max-device-width: 480px) {
      width: 320px;
      max-width: 320px;
    }

    ul {
      li {
        a {
          background-color: rgba(31, 54, 193, 0.5);

          &.active {
            background-color: @color-primary;
          }
        }
      }
    }
  }

  .introjs-tooltipbuttons {
    padding: 0px;
    padding-top: 20px;
    border: none;

    .introjs-button {
      border-radius: 8px;
      border: none;

      font-weight: 600;
      font-size: @font-size-18;

      padding: 8px 24px;

      color: @color-white;
      background-color: @color-primary;

      text-shadow: none;

      &.introjs-disabled {
        background: #d4d4d4;
      }
    }
  }

  .introjs-arrow {
    border: 12px solid transparent;

    &.left {
      left: -22px;
      top: 22px;
      border-right-color: @color-white;
    }
  }
}
